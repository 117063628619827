exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-codecademy-projects-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/work/codecademy-projects/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-codecademy-projects-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-intuit-turbotax-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/work/intuit-turbotax/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-intuit-turbotax-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-oswald-labs-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/work/oswald-labs/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-oswald-labs-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-planta-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/work/planta/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-planta-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-science-world-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/work/science-world/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-science-world-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-searchie-web-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/work/searchie-web/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-work-searchie-web-index-mdx" */)
}

